import Day from "./day";
import Draft from "./Draft";
import DailyCheckImage from "./dailyCheckImage";
import UploadForm from "./uploadForm";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Button from "react-bootstrap/Button";
import "bootstrap/dist/css/bootstrap.min.css";
import Card from "react-bootstrap/Card";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import "../App.css";
function BackCounter() {
  const admin = sessionStorage.getItem("lavender");
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false); // 初始狀態應為 false，這裡僅做範例

  const style1 = {
    margin: "20px",
  };
  useEffect(() => {
    if (admin != "admin") {
      alert("尚未登入");
      navigate("/login");
    }
  }, [navigate]);
  return (
    <div className="title-bg">
      <Navbar className="bg-body-tertiary">
        <Container>
          <Navbar.Brand href="/">
            <img
              alt=""
              src="/logo192.png"
              width="30"
              height="30"
              className="d-inline-block align-top"
            />{" "}
            小草spa
          </Navbar.Brand>
        </Container>
      </Navbar>
      <Container>
        <br></br>
        <Card>
          <div class="row justify-content-center ">
            <div class="col-12 text-center">
              <h4 style={style1}>🙌🏻 手工 🙌🏻</h4>
            </div>
          </div>
          <Draft time={"day"}></Draft>
        </Card>
        <br></br>
        {/* <Card>
          <div class="row justify-content-center ">
            <div class="col-12 text-center">
              <h4 style={style1}>🚿 日洗 🚿</h4>
            </div>
          </div>
          <Draft time={"night"}></Draft>
        </Card> */}
        <br></br>
        {/* <Card>
          <div class="row justify-content-center ">
            <div class="col-12 text-center">
              <h4 style={style1}>🚿🚿🚿🚿🚿🚿</h4>
            </div>
          </div>
          <Draft time={"shower"}></Draft>
        </Card>
        <br></br> */}
        <div className="separator"></div>

        <Card>
          <div class="row justify-content-center ">
            <div class="col-12 text-center">
              <h4 style={style1}>每日顯示照片</h4>
            </div>
          </div>
          {/* 每日照片 */}
          <DailyCheckImage></DailyCheckImage>
        </Card>
        <div className="separator"></div>
        <Card>
          <div class="row justify-content-center ">
            <div class="col-12 text-center">
              <h4 style={style1}>上傳/更新照片</h4>
            </div>
          </div>
          <UploadForm></UploadForm>
        </Card>
      </Container>
    </div>
  );
}

export default BackCounter;
