import Nav from "react-bootstrap/Nav";
import Card from "react-bootstrap/Card";
import text from "../tempText/text.json";
import axios from "axios";
import { useState, useEffect } from "react";
import { localDevelopmentPath } from "../tempText/env";

function Day() {
  const today = new Date();
  const tomorrow = new Date(today);
  const [textOne, setTextOne] = useState("");
  const [textTwo, setTextTwo] = useState("");
  const [textThree, setTextThree] = useState("");
  const [isLoading1, setIsLoading1] = useState(true);
  const [isLoading2, setIsLoading2] = useState(true);
  const [isLoading3, setIsLoading3] = useState(true);

  function dataFormatTrans(data) {
    let slicedData = data.slice(0, -1);
    let returnDataArray = slicedData.split(",");
    // console.log(returnDataArray);
    return returnDataArray;
  }
  useEffect(() => {
    // 統一call /day /night /shower api
    axios
      .get(`${localDevelopmentPath}day`)
      .then((res) => {
        let tempText = res.data;
        // console.log("day db text: " + tempText);
        setTextOne(dataFormatTrans(tempText));
        setIsLoading1(false);
      })
      .catch((err) => {
        console.log("err:" + err);
        setIsLoading1(false);
      });
    axios
      .get(`${localDevelopmentPath}night`)
      .then((res) => {
        let tempText = res.data;
        // console.log("day db text: " + tempText);
        setTextTwo(dataFormatTrans(tempText));
        setIsLoading2(false);
      })
      .catch((err) => {
        console.log("err:" + err);
        setIsLoading2(false);
      });
    // axios
    //   .get(`${localDevelopmentPath}shower`)
    //   .then((res) => {
    //     let tempText = res.data;
    //     console.log("day db text: " + tempText);
    //     setTextThree(dataFormatTrans(tempText));
    //     setIsLoading3(false);
    //   })
    //   .catch((err) => {
    //     console.log("err:" + err);
    //     setIsLoading3(false);
    //   });
  }, []);

  tomorrow.setDate(today.getDate());

  const options = {
    timeZone: "Asia/Taipei",
    month: "2-digit",
    day: "2-digit",
  };

  const tomorrowDate = tomorrow.toLocaleString("en-US", options);
  const options2 = {
    timeZone: "Asia/Taipei",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  };

  // 現在時間
  const now = new Date();

  // 轉為台北時間
  const taipeiTime = new Date(
    now.getTime() + (8 * 60 - now.getTimezoneOffset()) * 60000
  );

  // 减去3hr
  taipeiTime.setHours(taipeiTime.getHours() - 19);

  // 格式化日期
  const adjustedDate = taipeiTime.toLocaleString("en-US", options);

  const style1 = {
    margin: "20px",
  };
  const styleDate = {
    backgroundColor: "#CECEFF",
  };
  const styleHand = {
    backgroundColor: "#D2E9FF",
  };
  const styleDayWash = {
    backgroundColor: "#DFFFDF",
  };

  return (
    <>
      <div class="row justify-content-center ">
        <div class="col-12 text-center">
          <Card style={styleDate}>
            <h4 style={style1}>{adjustedDate}</h4>
          </Card>
          <br></br>
          <Card style={styleHand}>
            <h4 style={style1}>🙌🏻 手工 🙌🏻</h4>
            {isLoading1 ? (
              // loading中顯示loading
              <p>Loading...</p>
            ) : (
              <>
                {textOne.map((item) => (
                  <Card.Body key={item}>
                    <Card.Title>{item}</Card.Title>
                  </Card.Body>
                ))}
              </>
            )}
          </Card>
          <br></br>
          {/* <Card style={styleDayWash}>
            <h4 style={style1}>🚿 日洗 🚿</h4>
            {isLoading2 ? (
              // loading中顯示loading
              <p>Loading...</p>
            ) : (
              <>
                {textTwo.map((item) => (
                  <Card.Body key={item}>
                    <Card.Title>{item}</Card.Title>
                  </Card.Body>
                ))}
              </>
            )}
          </Card>
          <br></br> */}
          <Card>
            <Card.Body>
              <Card.Title> ❗❗不強迫消費❗❗ </Card.Title>
              有任何問題歡迎禮貌詢問唷<br></br> &#9888;&#65039; ⚠️<br></br>
              預約後如欲有事取消<br></br>
              請提早2小時告知 <br></br>如集滿兩次臨時取消印章 <br></br>
              下次預約僅可現場排候位 <br></br>才能把臨時取消印章消除 <br></br>
              🙇🏻‍♀️感謝各位守時的帥哥們🙇🏻‍♀
            </Card.Body>

            {/* {isLoading3 ? (
              // 如果数据正在加载，则显示加载状态
              <p>Loading...</p>
            ) : (
              <>
                {textThree.map((item) => (
                  <Card.Body key={item}>
                    <Card.Title>{item}</Card.Title>
                  </Card.Body>
                ))}
              </>
            )} */}
          </Card>
        </div>
      </div>
    </>
  );
}

export default Day;
